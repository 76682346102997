<template>
  <u-popover overlay>
    <core-responsive-button size="sm" square :icon="COMMON_ICONS.add">Add Files</core-responsive-button>
    <template #panel>
      <div class="p-4 w-80 text-center flex flex-col gap-3">
        <h3 class="font-semibold text-xl text-gradient-blue">Make this Album Awesome!</h3>
        <p>Log In to FOREVER to add your photos and be part of this shared memory.</p>
        <u-button
            block
            @click="signUp"
            size="xl"
            label="Log In & Add Photos"
        />
      </div>
    </template>
  </u-popover>
</template>
<script setup>
  function signUp() {
    useExternalRedirect().signUp(window.location);
  }
</script>
